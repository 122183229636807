.smallScreenList {
  flex-direction: column;
  position: absolute;
  top: 150px;
  z-index: 111;
  background: #fff;
  width: 100%;
  left: 0;
  right: 0;
  padding: 20px;
  transition: 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  box-shadow: 2px 5px 15px #e7e7e7;
}

.smallScreenList.active {
  top: 100px;
  opacity: 1;
  visibility: visible;
}
