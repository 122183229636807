.mainDiv {
  font-family: "Karla", sans-serif;
  background:linear-gradient(to right, rgb(0,0,0,.3), rgb(0,0,0,.3)), url("https://etimg.etb2bimg.com/photo/91574709.cms");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #ffffff;
  padding-bottom: 75px;
}

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.navbar-dark .navbar-text {
    color: #ffffff;
    opacity: 1;
    padding: 0 20px;
    border-right: 1px solid #ffffff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.page-title {
    font-size: 80px;
    font-weight: bold;
    margin-bottom: 6px;
    color: #fff;
    text-align: left;
}


.page-description {
    max-width: 600px;
    font-size: 18px;
    margin-bottom: 59px;
    color: #fff;
    text-align: left;
}