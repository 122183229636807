.Faqsbanner{
    background: url("../Images/About\ Us/hero-bg-1.jpg");
}

.section-heading{
    text-align: left;
}


.accordion-body p{
    text-align: left;
    
}

.single-faq h5{
    font-family: var(---font1);
}