
.process-item {
    display: inline-block;
    position: relative;
}
.process-item .process-item-number {
    background-color:#033361;
    color: #fff;
    font-size: 2.6rem;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translate(-127%,-21%);
    transform: translate(-127%,-21%);
    border-radius: 5px;
    padding: 0 5px;
}

.process-item h3 {
    font-size: 1.777rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
}
@media (max-width:500px) {
    .process-item h3{
        font-size: 1.3rem;
    }
}

.process-item p {
    font-size: .85rem;
}

.process-item .process-item-number, .process-item h3 {
    font-family: Lato,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
    font-weight: 900;
}

.process-item ul{
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
}

.process-item ul li{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .9rem;
}

.process-item ul li svg{
    color: green;
    margin-right: 5px;
}