.section-heading h2{
    color: #000;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 10px;
}


.featureContent h3{
    font-size: 2rem;
    color: #000;
}

.featureContent p {
    color: #444;
    font-size: .9rem;
    text-align: left;
}

.featureContent ul {
    margin: 10px 0 0 0;
    padding: 0;
}

.featureContent ul li{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    font-size: .9rem;
}

.featureContent ul li svg{
    margin-right: 5px;
    color: green;
}

.patientAppImage {
    max-width: 400px;
    margin: auto;
}

.collapse {
    visibility: visible;
}


.uploadButton {
    height: 35px;
    font-size: 1rem;
    background-color: green;
    color: #fff;
    transition: 0.5s ease-in-out;
    border: unset !important;
  }
  
  .uploadButton:hover,
  .uploadButton:focus {
    color: #fff !important;
  }
  
  .formInput,
  .formTextArea textarea {
    border: 1px solid #000 !important;
    border-radius: 5px !important;
    min-height: 40px;
    background-color: #f9f9f9 !important;
    transition: 0.5s ease-in-out;
    box-shadow: unset !important;
    outline: unset;
  }
  
  .formInput:hover,
  .formInput:focus,
  .formTextArea textarea:hover,
  .formTextArea textarea:focus {
    box-shadow: unset !important;
    border: 1px solid #000 !important;
    background-color: #fff !important;
    transition: 0.5s ease-in-out;
    outline: unset !important;
  }
  .ant-select-selector {
    background: #f9f9f9 !important;
    border-color: #000 !important;
    box-shadow: unset !important;
    min-height: 40px;
    margin: unset;
    padding: unset;
    height: 40px;
    width: 100%;
  }
  
  .ant-select-selector:hover {
    background-color: #fff !important;
    border-color: #000;
  }

  .filterOption .formInput,
  .filterOption .ant-select-selector {
    height: 35px !important;
    min-height: unset !important;
    max-width: 110px !important;
  }
  


  .bookingDetails{
    padding: 20px 30px;
    background-color: aliceblue;
  
  }
  
  .doctorDetailSeprate p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }  

  .slotCard {
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    border: unset;
    height: 40px;
    min-width: 100px;
    background-color: rgb(9, 57, 101);
    margin: 5px;
  }
  
  .Clickbtn {
    border-radius: 7px !important;
    color: white !important;
    background-color: rgb(9, 57, 101) !important;
    font-size: 14px !important;
    padding: 7px 15px !important;
    text-transform: capitalize !important;
    border: none !important;
    border: 2px solid rgb(9, 57, 101) !important;
    transition: all 0.6s ease-in-out 0s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    min-height: 40px;
  }
  
  .Cancelbtn {
    border-radius: 7px !important;
    color: white !important;
    background-color: rgb(197, 4, 28) !important;
    font-size: 16px !important;
    padding: 7px 15px !important;
    text-transform: capitalize !important;
    border: none !important;
    /* border: 2px solid var(---primaryColour) !important; */
    transition: all 0.6s ease-in-out 0s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-weight: 500;
    min-height: 40px;
  }
  .submitBtn {
    border-radius: 7px !important;
    color: white !important;
    background-color: green !important;
    font-size: 16px !important;
    padding: 7px 15px !important;
    text-transform: capitalize !important;
    border: none !important;
    font-weight: 500;
    transition: all 0.6s ease-in-out 0s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .doctorDetailSeprate h4{
    font-style: bold;
    font-size: 1.6rem;
  }


.slotCard.selected{
    background-color: rgb(1, 155, 1) !important;
    cursor: not-allowed;
  }
  
  .slotCard.booked{
    background-color: rgba(255, 0, 0) !important; 
  }
  
  .slotCard.disabled{
    background-color: rgb(0 0 0 / 25%) !important;
    cursor: not-allowed;
  }

  .slotList{
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
  }