@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

*{
  margin: 0;
  padding: 0;
}

button:focus{
  outline: unset;
}
html,
body{
  width: 100%;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}